
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
    name: "frequently-asked-questions",
    setup() {
        const store = useStore();
        return {
            faqSections: computed(() => store.state.faqSections)
        };
    }
});
