import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23c0da35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "col-xl-10 offset-xl-1 mb-3"
}
const _hoisted_3 = { class: "col-12 mb-4" }
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col-xl-10 offset-xl-1" }
const _hoisted_6 = ["id"]
const _hoisted_7 = ["data-bs-target"]
const _hoisted_8 = ["id", "data-bs-parent"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqSections, (section, sectionIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `section-${sectionIndex}`,
        class: "row"
      }, [
        (section.heading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", null, _toDisplayString(section.heading), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                id: `accordion-${sectionIndex}`,
                class: "accordion"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.items, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `section-${index}`,
                    class: "accordion-item"
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass([[
                                        'accordion-button',
                                        { collapsed: index > 0 }
                                    ], "gradient-secondary font-raleway"]),
                      type: "button",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": `#collapse-${sectionIndex}-${index}`
                    }, _toDisplayString(item.question), 11, _hoisted_7),
                    _createElementVNode("div", {
                      id: `collapse-${sectionIndex}-${index}`,
                      class: _normalizeClass([
                                        'accordion-collapse collapse',
                                        { show: index == 0 }
                                    ]),
                      "data-bs-parent": `#accordion-${sectionIndex}`
                    }, [
                      _createElementVNode("div", {
                        class: "accordion-body",
                        innerHTML: item.answer
                      }, null, 8, _hoisted_9)
                    ], 10, _hoisted_8)
                  ]))
                }), 128))
              ], 8, _hoisted_6)
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}